export default {
  home: () => {
    return '/tour/nutrition-monthly-start';
  },
  homeFitOver40: () => {
    return '/tour/wall-pilates';
  },
  homeWalkingIndoor: () => {
    return '/tour/walking-indoor';
  },
  homePilates: () => {
    return '/tour/pilates';
  },
  homeSomaticYoga: () => {
    return '/tour/somatic-yoga';
  },
  homeSomaticExercises: () => {
    return '/tour/somatic-exercises';
  },
  homePilatesNow: () => {
    return '/tour/pilates-now';
  },
  homeNutritionKeto: () => {
    return '/tour/nutrition-keto';
  },
  homeNutritionKetoMeal: () => {
    return '/tour/nutrition-keto-meal';
  },
  homeNutritionKetoWeekly: () => {
    return '/tour/nutrition-keto-weekly';
  },
  homeNutritionKetoAge: () => {
    return '/tour/nutrition-keto-age';
  },
  homeNutritionKetoWeeklyAge: () => {
    return '/tour/nutrition-keto-weekly-age';
  },
  homeNutritionKetoWeeklyMeal: () => {
    return '/tour/nutrition-keto-meal-weekly';
  },
  homePilatesChallenge: () => {
    return '/tour/pilates-28-day-challenge';
  },
  homePilatesChallengeGenericUs: () => {
    return '/tour/pilates-28-day-challenge-generic-us';
  },
  homePilatesChallengeGenericOthers: () => {
    return '/tour/pilates-28-day-challenge-generic-others';
  },
  homeYogaChallengeGenericOthers: () => {
    return '/tour/yoga-28-day-challenge-generic-others';
  },
  homePilatesChairChallenge: () => {
    return '/tour/pilates-28-day-chair-challenge';
  },
  homePilatesFlatBelly: () => {
    return '/tour/pilates-28-day-flat-belly';
  },
  homePilatesChallengeSomatic: () => {
    return '/tour/pilates-somatic-28-day-challenge';
  },
  homeMacroDietForWomen: () => {
    return '/tour/macro-diet-for-women';
  },
  homeBodyNutrition: () => {
    return '/tour/body-nutrition';
  },
  homeNutritionProtein: () => {
    return '/tour/nutrition-protein-plan';
  },
  homeNutritionWeeklyStart: () => {
    return '/tour/nutrition-weekly-start';
  },
  homeNutritionMonthlyStart: () => {
    return '/tour/nutrition-monthly-start';
  },
  homeNutritionMonthlyGenericUS: () => {
    return '/tour/nutrition-monthly-generic-us';
  },
  homeNutritionMonthlyGenericOthers: () => {
    return '/tour/nutrition-monthly-generic-others';
  },
  homeNutrition28DayNoSugarChallenge: () => {
    return '/tour/nutrition-28-day-sugar-challenge';
  },
  homeNutrition28DayWeightLossChallenge: () => {
    return '/tour/nutrition-28-day-wl-challenge';
  },
  homeYogaChallenge: () => {
    return '/tour/yoga-28-day-challenge';
  },
  homeMenopauseChallenge: () => {
    return '/tour/yoga-menopause-challenge';
  },
  homeYogaChallengeGenericUs: () => {
    return '/tour/yoga-28-day-challenge-generic-us';
  },
  homeNutritionMonthlyCortisolCleanse: () => {
    return '/tour/nutrition-monthly-cortisol-cleanse';
  },
  homeNutritionWeeklyCortisolCleanse: () => {
    return '/tour/nutrition-weekly-cortisol-cleanse';
  },
  homeNutritionWeeklyMediterraneanDiet: () => {
    return '/tour/nutrition-weekly-mediterranean-diet';
  },
  homeYogaChallengeSenior: () => {
    return '/tour/yoga-28-day-challenge-senior';
  },
  homeChairYogaV1: () => {
    return '/tour/yoga-28-day-challenge-senior-v1';
  },
  homeChairYogaV2: () => {
    return '/tour/yoga-28-day-challenge-senior-v2';
  },
  homeYogaLazy: () => {
    return '/tour/yoga-lazy';
  },
  login: () => {
    return '/login';
  },
  tour: () => {
    return '/tour';
  },
  quiz: () => {
    return '/quiz/start';
  },
  manage: () => {
    return '/manage';
  },
  'manage-cancel-subscription': () => {
    return '/manage/cancel-subscription';
  },
  'manage-subscription': () => {
    return '/manage/subscription';
  },
  'manage-your-subscription': () => {
    return '/manage-your-subscription';
  },
  'request-password-reset': () => {
    return '/request-password-reset';
  },
  'our-cause': () => {
    return '/our-cause';
  },
  aboutUs: () => {
    return '/#about';
  },
  termsFit: () => {
    return '/terms-of-service';
  },
  privacyFit: () => {
    return '/privacy-policy';
  },
  blog: () => {
    return '/blog';
  },
  reviews: () => {
    return '/reviews';
  },
  contact: () => {
    return '/contact';
  },
  terms: () => {
    return '/terms';
  },
  privacy: () => {
    return '/privacy';
  },
  // Tapptitude URLs
  appLogin: () => {
    return '/manage/app/login';
  },
  appRequestPasswordReset: () => {
    return '/manage/app/request-password-reset';
  },
};
