
import { mapActions } from 'vuex';

import { AUTH_LOGIN } from '@/components/auth/helpers/auth-component-names';
import { QUIZ_VARIANTS } from '@/modules/tour/domain/tour';

export default {
  name: 'LayoutDefault',
  middleware: ['redirect-region', 'check-source'],

  head() {
    return {
      bodyAttrs: {
        class: this.$exp.$classes.join(' '),
      },
      htmlAttrs: {
        lang: this.$i18n.locale.split('-')[0] || 'en',
      },
      style: [
        // Add a <style> tag with your custom CSS variables and styles
        {
          cssText: this.styles,
        },
      ],
    };
  },
  computed: {
    nutritionMonthlyStart() {
      return {
        '.main-header': {
          '--c-background-color': '#F9FBFE !important',
        },
      };
    },
    pilatesStyles() {
      return {
        ':root': {
          '--c-primary': '#007BBD !important',
          '--c-primary-dark': '#0074B2 !important',
          '--c-primary-light': '#3B99CC !important',
        },
      };
    },
    ketoStyles() {
      return {
        ':root': {
          '--c-primary': '#3B7841 !important',
          '--c-primary-dark': '#2F6D35 !important',
          '--c-primary-light': '#ADC6AF !important',
          '--c-primary-light-1': '#4D8A53 !important',
        },
        '.main-header': {
          '--c-background-color': '#F5F5EE !important',
        },
      };
    },
    yogaStyles() {
      return {
        ':root': {
          '--c-primary': 'var(--yoga-primary) !important',
          '--c-primary-dark': 'var(--yoga-primary-dark) !important',
          '--c-primary-light': 'var(--yoga-primary-light) !important',
        },
        '.main-header': {
          '--c-background-color': 'var(--yoga-primary) !important',
        },
        '.button': {
          'box-shadow': 'none !important',
        },
      };
    },
    somaticYogaStyles() {
      return {
        ':root': {
          '--c-primary': 'var(--somatic-yoga-primary) !important',
          '--c-primary-dark': 'var(--somatic-yoga-primary-dark) !important',
          '--c-primary-light': 'var(--somatic-yoga-primary-light) !important',
        },
        '.main-header': {
          '--c-background-color': 'var(--somatic-yoga-primary) !important',
        },
        '.button': {
          'box-shadow': 'none !important',
        },
      };
    },
    fitnessWalkingStyles() {
      return {
        ':root': {
          '--c-primary': 'var(--fitness-walking-primary) !important',
          '--c-primary-dark': 'var(--fitness-walking-primary-dark) !important',
          '--c-primary-light': 'var(--fitness-walking-primary-light) !important',
        },
        '.main-header': {
          '--c-background-color': '#FFFFFF !important',
        },
        '.main-footer .main-footer__main': {
          'background-color': '#191C35 !important',
        },
        '.main-footer .main-footer__social': {
          'border-radius': '100px',
        },
        '.button': {
          'box-shadow': 'none !important',
        },
      };
    },
    quizVariantStyles() {
      return {
        [QUIZ_VARIANTS.PILATES_NEW_CHECKOUT]: this.pilatesStyles,
        [QUIZ_VARIANTS.WALL_PILATES]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_NOW]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_NEW_LANDER]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_LANDER]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V1]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V2]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V3]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V4]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GOAL_STEP]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LP_NO_FOOTER_V1]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LP_NO_FOOTER_V2]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LP_NO_FOOTER_V3]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GENERIC_US]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GENERIC_OTHERS]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_28_DAY_CHAIR_CHALLENGE]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_28_DAY_FLAT_BELLY]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_LEGAL_DISCLAIMER]: this.pilatesStyles,
        [QUIZ_VARIANTS.NUTRITION_KETO]: this.ketoStyles,
        [QUIZ_VARIANTS.NUTRITION_KETO_MEAL]: this.ketoStyles,
        [QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY]: this.ketoStyles,
        [QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY_AGE]: this.ketoStyles,
        [QUIZ_VARIANTS.NUTRITION_KETO_MEAL_WEEKLY]: this.ketoStyles,
        [QUIZ_VARIANTS.NUTRITION_KETO_AGE]: this.ketoStyles,
        [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE]: this.yogaStyles,
        [QUIZ_VARIANTS.YOGA_MENOPAUSE_CHALLENGE]: this.yogaStyles,
        [QUIZ_VARIANTS.SOMATIC_YOGA]: this.somaticYogaStyles,
        [QUIZ_VARIANTS.SOMATIC_EXERCISES]: this.somaticYogaStyles,
        [QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY_HIGH_PROTEIN]: this.nutritionMonthlyStart,
        [QUIZ_VARIANTS.NUTRITION_MONTHLY_GENERIC_US]: this.nutritionMonthlyStart,
        [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_GENERIC_US]: this.yogaStyles,
        [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_GENERIC_OTHERS]: this.yogaStyles,
        [QUIZ_VARIANTS.YOGA_FULL_BODY_BEFORE_AFTER_IMAGES_V1]: this.yogaStyles,
        [QUIZ_VARIANTS.YOGA_FULL_BODY_BEFORE_AFTER_IMAGES_V2]: this.yogaStyles,
        [QUIZ_VARIANTS.WALKING_INDOOR]: this.fitnessWalkingStyles,
        [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_SENIOR]: this.yogaStyles,
        [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_SENIOR_V1]: this.yogaStyles,
        [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_SENIOR_V2]: this.yogaStyles,
        [QUIZ_VARIANTS.YOGA_LAZY]: this.yogaStyles,
      };
    },
    styles() {
      return Object.entries(this.quizVariantStyles[this.getQuizVariant] || {})
        .map(([key, value]) =>
          typeof value === 'object'
            ? `${key} { ${Object.entries(value)
                .map(([prop, val]) => `${prop}: ${val};`)
                .join(' ')} }`
            : `${key}: ${value}`,
        )
        .join(' ');
    },
  },
  watch: {
    $route() {
      this.setAuthComponentName(AUTH_LOGIN);
    },
  },
  mounted() {
    // This method will validate all query in the url and store them in vuex
    this.queryHandler();

    if (window && window.balanceText && typeof window.balanceText === 'function') {
      window.balanceText('.balance-text');
    }
  },
  methods: {
    ...mapActions('auth', ['setAuthComponentName']),
  },
};
